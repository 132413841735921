import React from 'react';
import { useDispatch } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faWrench,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';

import './index.css';
import translations from '../../../translations/sidebar';
import GroupsDropdown from '../GroupsDropdown';
import { http } from '../../../services/http';
import { logoutURL } from '../../../utils/urls';
import { cleanUpStore } from '../../../reducers';
import CustomAccessDropdown from '../CustomAccessDropdown';

const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await http.get(logoutURL());
      localStorage.removeItem('user');
      localStorage.removeItem('courseTree');
      localStorage.removeItem('courseTreeTimestamp');
      dispatch(cleanUpStore());
      history.push('/login');
      // eslint-disable-next-line no-empty
    } catch (e) { }
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const userHasCustomAccess = !(!user.customAccess || user.customAccess === undefined || user.customAccess.length === 0);

  return (
    <>
      <Navbar
        variant="dark"
        expand={false}
        className="sidebar d-flex flex-column justify-content-start h-100"
      >
        <Navbar.Brand href="/" className="mx-auto py-4 sidebar-navbar-brand">
          {translations.brand}
        </Navbar.Brand>
        <hr />
        <Nav
          defaultActiveKey="dashboard"
          className="justify-content-left w-100 sidebar-navbar-nav"
        >
          <Nav.Item>
            <Nav.Link href="/" eventKey="home-link">
              <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
              <span>{translations.home}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <GroupsDropdown />
          </Nav.Item>
          {userHasCustomAccess &&
            <Nav.Item>
              <CustomAccessDropdown />
            </Nav.Item>
          }
          <hr />
          <Nav.Item>
            <Nav.Link href="/account" eventKey="account-link">
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              <span>{translations.account}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/created-accounts" eventKey="account-link">
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              <span>{translations.createdStudents}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-link" onClick={handleLogout}>
            <FontAwesomeIcon icon={faWrench} className="mr-2" />
            <span>{translations.logout}</span>
          </Nav.Item>
        </Nav>
      </Navbar>
    </>
  );
};

export default Sidebar;
