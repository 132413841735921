const translations = {
  importUsersFrom: 'Import users from',
  googleClassroom: 'Google Classroom',
  emailList: 'Email List',
  actionRequired: 'Action required',
  clickToOpen:
    'Click next to allow Notysing access to Google Classroom and import the users.',
  chooseGroups: 'Choose groups',
  chooseGroup: 'Choose group',
  importFromGoogleClassroom: 'Import from Google Classroom',
  importFromClassroom: 'Import from Classroom:',
  importToGroup: 'Import to group:',
  clickToChooseUsers:
    'Click next to choose which users from the selected Classroom to import.',
  clickToAddUsers: 'Click next to add users',
  newGroupName: 'New group name:',
  selectUsersToImport: 'Select users to import',
  addUsersToImport: 'Add users to import',
  importCompleted: 'Import completed',
  usersImportedSuccessfully: 'Users imported successfully!',
  userCanAccess:
    'Imported users can now access Notysing by using Google login.',
  seeProgress: 'Go to the group to see the user progress.',
  pasteUsers:
    'Paste a list of users, one per line, with email addresses and display names (optional), separated by tab, comma or semicolon.',
  ok: 'OK',
  import: 'Import',
  importing: 'Importing...',
  cancel: 'Cancel',
  next: 'Next',
  someUsersFailedToImport:
    'Some of the users failed to import due to an error, check your group and try again later.',
  someUsersFailedToImportFromEmailList:
    'Some of the users failed to import because no matching user was found. Check your group to see what users were imported, and try again later.',
  partialSuccess: 'Partial success',
};

export default translations;
