/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useState } from 'react';
import { httpRes } from '../services/http';
import { getCustomAccessTreeURL } from '../utils/urls';
import reshapeCourseTree from '../utils/reshapeCourseTree';
import isCourseTreeUpdated from '../utils/isCourseTreeUpdated';

export const useCustomAccessTrees = () => {
  const [customAccessTrees, setCustomAccessTrees] = useState([{}]);
  const setCustomAccessTreesCB = useCallback(setCustomAccessTrees, []);

  useEffect(() => {
    const userExists =
      localStorage.getItem('user') !== 'undefined' &&
      localStorage.getItem('user') !== null;

    if (!userExists) {
      console.log("No user");
      return;
    };

    const user = JSON.parse(localStorage.getItem('user'));

    if (!user.customAccess || user.customAccess === undefined || user.customAccess.length === 0) {
      return;
    }

    let customAccessItems = user.customAccess;

    const getCustomAccessTreesAsync = async () => {

      let customAccessTrees = {};

      for (const customAccessItem of customAccessItems) {
        try {
          const customAccessTree = await httpRes.get(getCustomAccessTreeURL(customAccessItem));
          customAccessTrees[customAccessItem] = reshapeCourseTree(customAccessTree.data);
          // eslint-disable-next-line no-empty
        } catch (e) { }
      }
      setCustomAccessTreesCB(customAccessTrees);
      const customAccessTreesTimestamp = Date.now();
      try {
        localStorage.setItem(
          'customAccessTrees', JSON.stringify(customAccessTrees)
        );
        localStorage.setItem(
          'customAccessTreesTimestamp',
          JSON.stringify(customAccessTreesTimestamp)
        );
        // eslint-disable-next-line no-empty
      } catch (e) { }
    };

    if (localStorage.getItem('customAccessTrees')) {
      const customAccessTreesTimeStamp = localStorage.getItem('customAccessTreesTimestamp');
      const customAccessTrees = JSON.parse(localStorage.getItem('customAccessTrees'));

      if (!customAccessTreesTimeStamp 
        || !isCourseTreeUpdated(customAccessTreesTimeStamp) 
        || customAccessItems.length !== Object.keys(customAccessTrees).length) {
        getCustomAccessTreesAsync();
      } else {
        setCustomAccessTreesCB(customAccessTrees);
      }
    } else {
      getCustomAccessTreesAsync();
    }
  }, [setCustomAccessTreesCB]);

  return customAccessTrees;
};
