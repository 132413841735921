import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = () => {
  const isLoadingSpinnerVisible = useSelector(
    state => state.loadingSpinnerReducer.isVisible
  );

  let LoadingSpinner = null;
  if (isLoadingSpinnerVisible) {
    LoadingSpinner = (
      <Spinner className="loading-spinner" animation="border" variant="success" />
    );
  }

  return LoadingSpinner;
};

export default LoadingSpinner;
