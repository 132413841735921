const translations = {
  forgotPassword: 'RESET PASSWORD',
  invalidEmail: 'Email is invalid.',
  sendRequest: 'SEND REQUEST',
  backToLogin: 'Back to login',
  successMessage: 'Reset email has been sent. Check your inbox.',
  serviceIsUnavailable:
    'Resetting password is disabled temporarily due to a database issue.',
  checkLater: 'Please check back again later.',
};

export default translations;
