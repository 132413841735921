import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import translations from '../../../../translations/content';
import CreateUsersModal from './CreateUsersModal';

const CreateUsers = ({ updateStudentsList }) => {
  const [step, setStep] = useState(0);

  const openModal = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.creatorKey) {
      setStep(2);
    } else {
      setStep(1);
    }
  };

  const closeModal = () => {
    setStep(0);
    if (updateStudentsList) {
      updateStudentsList();
    }
  };

  return (
    <>
      <Button
        variant="success"
        size="sm"
        className="mt-4 mb-4 ml-4"
        onClick={openModal}
      >
        {translations.createUsers}
      </Button>
      {!!step && (
        <CreateUsersModal
          step={step}
          setStep={setStep}
          show={!!step}
          onHide={closeModal}
        />
      )}
    </>
  );
};

export default CreateUsers;
