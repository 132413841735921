import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import Sidebar from './Sidebar';
import ErrorNotification from '../ErrorNotification';
import HomeScreen from './HomeScreen';
import GroupContent from './GroupContent';
import Account from './Account';
import CreatedStudentsList from './CreatedStudentsList';
import { fetchGroups } from './GroupsDropdown/duck';
import { getUsersfromGroup } from './Account/duck';
import PermanentNotification from './PermanentNotification';
import translations from '../../translations/permanentNotification';
import constants from '../../constants/account';
import { useCourseTree } from '../../hooks/useCourseTree';
import { useCustomAccessTrees } from '../../hooks/useCustomAccessTrees';

import UserTimeline from './UserTimeline';

const Home = () => {
  const dispatch = useDispatch();
  const { id, studentId, gId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;

  const groups = useSelector(state => state.groups);
  const groupsId = groups.map(group => group.id);
  const usersId = useSelector(state => state.usersId);

  let existingAmount = 0;
  let minAmountToUpgrade = 0;
  let numberOfSubscribedUsers = 0;
  let isEmailVerified = false;
  let isPaymentUpgradeRequired = false;
  const userExists =
    localStorage.getItem('user') !== 'undefined' &&
    localStorage.getItem('user') !== null;

  const roundUsersNumber = usersNumber => {
    const intPart = parseInt(usersNumber / 10, 10);
    const roundedUsersNumber = (intPart + 1) * 10;
    return roundedUsersNumber;
  };

  if (userExists) {
    const user = JSON.parse(localStorage.getItem('user'));

    // add teacher to array (if not already in a group)
    if (user && usersId.indexOf(user.id) === -1) {
      usersId.push(user.id);
    }

    existingAmount = usersId.length;
    minAmountToUpgrade =
      constants.minUsers > existingAmount
        ? constants.minUsers
        : roundUsersNumber(existingAmount);

    const identities =
      user && user.identities
        ? user.identities.map(identity => identity.provider)
        : [];
    const hasEmailIdentity = identities.includes('email');
    const emailIdentity = hasEmailIdentity
      ? user.identities.find(identity => identity.provider === 'email')
      : {};
    isEmailVerified = !!(emailIdentity && emailIdentity.emailVerified);
    const isSubscribed =
      user &&
      user.purchases &&
      user.purchases.subscriptions &&
      user.purchases.subscriptions.length > 0;
    numberOfSubscribedUsers = isSubscribed
      ? user.purchases.subscriptions[0].quantity
      : 0;
    isPaymentUpgradeRequired =
      isSubscribed && minAmountToUpgrade > numberOfSubscribedUsers;
  }

  useCourseTree();
  useCustomAccessTrees();

  useEffect(() => {
    dispatch(fetchGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groupsId.length) {
      groupsId.forEach(groupId => dispatch(getUsersfromGroup(groupId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsId.length]);

  const renderContent = () => {
    let content = <HomeScreen />;
    if (pathname === '/account') {
      content = (
        <Account
          minAmountToUpgrade={minAmountToUpgrade}
          existingAmount={existingAmount}
          numberOfSubscribedUsers={numberOfSubscribedUsers}
        />
      );
    }
    if (id && pathname.includes('groups')) {
      content = <GroupContent gId={id} />;
    }
    if (pathname === '/created-accounts') {
      content = <CreatedStudentsList />;
    }
    if (studentId && gId && pathname.includes('user-timeline')) {
      content = <UserTimeline id={studentId} groupId={gId} />;
    }
    return content;
  };

  const goToAccount = () => {
    history.push('/account');
  };

  return userExists ? (
    <>
      <ErrorNotification />
      {!isEmailVerified && (
        <PermanentNotification
          message={translations.verifyEmail}
          buttonText={translations.goToSettings}
          onClick={goToAccount}
        />
      )}
      {isPaymentUpgradeRequired && (
        <PermanentNotification
          message={translations.upgrade}
          buttonText={translations.goToSettings}
          onClick={goToAccount}
        />
      )}
      <div className="home-wrapper">
        <Sidebar />
        {renderContent()}
      </div>
    </>
  ) : (
    <div />
  );
};

export default Home;
