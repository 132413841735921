const translations = {
  createdStudentsAccount: 'Anonymous Student Accounts',
  studentId: 'Student ID',
  password: 'Password',
  creationDate: 'Creation date',
  actions: 'Actions',
  addToGroup: 'Add to group',
  generateNewPasswords: 'Generate new passwords',
  deleteAccounts: 'Delete accounts',
  selected: 'Selected',
  createStudents: 'Go to Home page to create student accounts.',
  success: 'Success',
  partialSuccessTitle: 'Partial success',
  deletedSuccessfully: 'Accounts have been successfully deleted.',
  partialSuccess: 'Partial success',
  deletedPartialy:
    'Some accounts have not been deleted due to an error. Please, try again later.',
  updatedSuccessfully: 'Accounts have been successfully updated.',
  updatedPartialy:
    'Some accounts have not been updated due to an error. Please, try again later.',
  error: 'Error',
  errorMessageGeneral: 'Something went wrong. Please, try again later.',
  yourTeacherCode: 'Your teacher code',
  usersAdded: 'Users added',
  usersAddedSuccessfully: 'Users successfully added to group!',
  addedPartialy:
    'Some accounts have not been added due to an error. Please, try again later.',
  cancel: 'Cancel',
  add: 'Add',
  chooseGroup: 'Choose group',
};

export default translations;
