import React, { useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { http } from '../../../../services/http';
import translations from '../../../../translations/account';
import validateEmail from '../../../../utils/validateEmail';
import constants from '../../../../constants/account';
import { updateEmailURL, getCurrentUserURL } from '../../../../utils/urls';

const InlineEmailForm = ({ initialEmail, handleSaveEmailSuccess }) => {
  const [email, setEmail] = useState(initialEmail);
  const [error, setError] = useState('');

  const handleChange = event => {
    event.preventDefault();
    const { value } = event.target;
    setEmail(value);
    setError('');
  };

  const handleBlur = () => {
    setError('');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { client, verifyEmailUrl } = constants;
    if (validateEmail(email)) {
      try {
        await http.post(updateEmailURL(), {
          email,
          client,
          verificationLink: `${window.location.host}${verifyEmailUrl}`,
        });
        try {
          const { data: updatedUser } = await http.get(getCurrentUserURL());
          localStorage.setItem('user', JSON.stringify(updatedUser));
          // eslint-disable-next-line no-empty
        } catch (e) {}
        handleSaveEmailSuccess();
      } catch (e) {
        let errorMessage = translations.errorMessageGeneral;
        if (e.response.status === 409) {
          errorMessage = translations.emailExists;
        }
        setError(errorMessage);
      }
    } else {
      setError(email ? translations.invalidEmail : translations.emptyEmail);
    }
  };

  return (
    <>
      <Row className="w-100">
        <Form className="pl-3 mb-3 w-100" onSubmit={handleSubmit} noValidate>
          <Form.Row>
            <Col xs={3}>
              <Form.Group className="mb-0">
                <Form.Control
                  isInvalid={!!error}
                  size="sm"
                  type="email"
                  placeholder="email"
                  value={email}
                  onChange={handleChange}
                  className="text-lowercase"
                />
                <Form.Control.Feedback type="invalid" className="text-left">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                onBlur={handleBlur}
              >
                {translations.save}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Row>
    </>
  );
};

export default InlineEmailForm;
