import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import constants from '../../constants/login';
import translations from '../../translations/login';
import { http } from '../../services/http';
import validateForm from '../../utils/validateForm';
import { loginURL, getCurrentUserURL, googleLoginURL } from '../../utils/urls';
import ErrorNotification from '../ErrorNotification';

const Login = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const { email, password } = values;
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      history.push('/');
    }
  }, [history]);

  const handleChange = event => {
    event.preventDefault();
    const { id, value } = event.target;
    setValues(prevValues => ({ ...prevValues, [id]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!Object.values(validateForm(values)).length) {
      try {
        const {
          data: { user },
        } = await http.post(loginURL(), { email, password });
        await http.get(getCurrentUserURL());
        localStorage.setItem('user', JSON.stringify(user));
        window.location.reload();
      } catch (e) {
        if (e.response.status === 403 || e.response.status === 401) {
          setErrors({
            email: translations.invalidEmailOrPassword,
            password: translations.invalidPasswordOrEmail,
          });
        }
      }
    } else {
      setErrors(validateForm(values));
    }
  };

  const googleSignIn = () => {
    const cb = `${window.location.origin}${constants.googleLoginCallbackRoute}`;
    window.location.href = googleLoginURL(cb);
  };

  return (
    <>
      <ErrorNotification />
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="overflow-hidden border-2 my-5">
              <Card.Body className="p-0">
                <Row className="justify-content-center">
                  <Col lg={9}>
                    <div className="p-5">
                      <Card.Title className="text-center">
                        {translations.login}
                      </Card.Title>
                      <Form
                        className="mt-5 mb-2"
                        onSubmit={handleSubmit}
                        data-testid="form"
                        noValidate
                      >
                        <Form.Group controlId="email">
                          <Form.Control
                            isInvalid={email && !!errors.email}
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            className="text-lowercase"
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-left"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="password">
                          <Form.Control
                            isInvalid={password && !!errors.password}
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-left"
                          >
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                          block
                          variant="primary"
                          type="submit"
                          disabled={Object.values(values).some(
                            value => value === ''
                          )}
                        >
                          {translations.login}
                        </Button>
                      </Form>
                      <div className="text-center mb-3">
                        <Link
                          to={{
                            pathname: '/request-reset-password',
                            state: { prevPath: window.location.pathname },
                          }}
                        >
                          {translations.forgotPassword}
                        </Link>
                      </div>
                      <hr className="w-100 mt-0" />
                      <Button
                        block
                        variant="danger"
                        type="submit"
                        className="mb-3"
                        onClick={googleSignIn}
                      >
                        <FontAwesomeIcon icon={faGoogle} className="mr-2" />
                        Use Google
                      </Button>
                      <div className="text-center mb-2">
                        <span>{`${translations.newUser} `}</span>
                        <Link to="/create-account">
                          {translations.createNewAccount}
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
