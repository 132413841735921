import React from 'react';
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import './index.css';

const usersListFormModalBody = ({
  usersList,
  onChange,
  label,
  placeholder,
}) => {
  return (
    <InputGroup>
      <Form.Label htmlFor="email-list">{label}</Form.Label>
      <FormControl
        id="email-list"
        as="textarea"
        placeholder={placeholder}
        rows={6}
        value={usersList}
        onChange={onChange}
        style={{ width: '100%' }}
      />
    </InputGroup>
  );
};

export default usersListFormModalBody;
