import React from 'react';
import { Form, Button } from 'react-bootstrap';

import translations from '../../../translations/resetPassword';

const ResetPasswordForm = ({ children, handleSubmit, values }) => {
  return (
    <Form
      className="mt-5 mb-5"
      onSubmit={handleSubmit}
      data-testid="form"
      noValidate
    >
      {children}
      <Button
        block
        variant="primary"
        type="submit"
        disabled={Object.values(values).some(value => value === '')}
      >
        {translations.resetPassword}
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
