import React from 'react';
import { Row, Button } from 'react-bootstrap';

const AccountButton = ({ onClick, disabled, variant, text }) => {
  return (
    <>
      <Row className="pl-3 pb-3 w-100">
        <Button
          className="text-uppercase"
          onClick={onClick}
          disabled={disabled}
          variant={variant}
          size="sm"
        >
          {text}
        </Button>
      </Row>
    </>
  );
};

export default AccountButton;
