import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import translations from '../../../../translations/content';
import ImportUsersModal from './ImportUsersModal';
import { setStep } from './GoogleClassroomCallback/duck';

const ImportUsers = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const step = useSelector(state => state.googleClassroomReducer.step);

  const openModal = () => {
    dispatch(setStep(1));
  };

  const closeModal = () => {
    const { pathname } = location;
    if (pathname !== '/') {
      history.push('/');
    }
    dispatch(setStep(0));
  };

  return (
    <>
      <p className="mt-4 ml-2">{translations.importUsersMessage}</p>
      <Button
        variant="success"
        size="sm"
        className="mt-4 ml-2"
        onClick={openModal}
      >
        {translations.importUsers}
      </Button>
      {step ? <ImportUsersModal show={!!step} onHide={closeModal} /> : null}
    </>
  );
};

export default ImportUsers;
