/*
 * action types
 */

export const SET_INFO_ALERT = 'SET_INFO_ALERT';
export const HIDE_INFO_ALERT = 'HIDE_INFO_ALERT';

/*
 * action creators
 */

export const setInfoAlert = infoAlert => {
  return {
    type: SET_INFO_ALERT,
    infoAlert,
  };
};

export const hideInfoAlert = () => {
  return {
    type: HIDE_INFO_ALERT,
  };
};

/*
 * Reducer
 */

const initialState = null;

const infoAlertReducer = (state = initialState, action) => {
  const { infoAlert } = action;

  if (infoAlert) {
    return infoAlert;
  }
  if (action.type === HIDE_INFO_ALERT) {
    return null;
  }

  return state;
};

export default infoAlertReducer;
