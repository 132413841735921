const translations = {
  actionRequired: 'Action required',
  chooseGroup: 'Choose group',
  clickToAddUsers: 'Click next to add users',
  newGroupName: 'New group name:',
  seeProgress: 'Go to the group to see the user progress.',
  pasteUsers:
    'Paste a list of users, one per line, with student IDs and passwords (optional), separated by tab, comma or semicolon.',
  ok: 'OK',
  cancel: 'Cancel',
  next: 'Next',
  create: 'Create',
  creating: 'Creating',
  teacherCode: 'Teacher Code',
  teacherCodeInfo:
    'To create anonymous student accounts, you first need a teacher code.',
  generateNewCode: 'Generate new code',
  acceptKeyMsg: 'Accept the code. Once accepted, this code cannot be changed.',
  codeExists: 'The code has been already assigned.',
  createAccounts: 'Create student accounts',
  userListPlaceholder: `  user123; 12345
  student1; PasswOrd`,
  someUsersFailedToCreate:
    'Some of the users failed to create due to an error:',
  usersCreated: 'Users created',
  usersCreatedSuccessfully: 'Users created successfully!',
  manageUsers: 'You can manage users from Created Students page.',
  partialSuccess: 'Partial success',
  addToGroup: 'Add to group',
};

export default translations;
