/*
 * action types
 */

export const SET_VISIBILITY = 'SET_VISIBILITY';

/*
 * action creators
 */

export const setSpinnerVisibility = isVisible => {
  return {
    type: SET_VISIBILITY,
    isVisible,
  };
};

/*
 * Reducers
 */

const initialState = {
  isVisible: false,
};

const loadingSpinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VISIBILITY:
      return {
        ...state,
        isVisible: action.isVisible,
      };
    default:
      return state;
  }
};

export default loadingSpinnerReducer;
