/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useState } from 'react';
import { httpRes } from '../services/http';
import { getCourseTreeURL } from '../utils/urls';
import reshapeCourseTree from '../utils/reshapeCourseTree';
import isCourseTreeUpdated from '../utils/isCourseTreeUpdated';

export const useCourseTree = () => {
  const [courseTree, setCourseTree] = useState([{}]);
  const setCourseTreeCB = useCallback(setCourseTree, []);

  useEffect(() => {
    const getCourseTreeAsync = async () => {
      try {
        const { data } = await httpRes.get(getCourseTreeURL());
        const reshapedCourseTree = reshapeCourseTree(data);
        setCourseTreeCB(reshapedCourseTree);
        const courseTreeTimestamp = Date.now();
        localStorage.setItem('courseTree', JSON.stringify(reshapedCourseTree));
        localStorage.setItem(
          'courseTreeTimestamp',
          JSON.stringify(courseTreeTimestamp)
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    if (localStorage.getItem('courseTree')) {
      const courseTreeTimeStamp = localStorage.getItem('courseTreeTimestamp');
      if (!courseTreeTimeStamp || !isCourseTreeUpdated(courseTreeTimeStamp)) {
        getCourseTreeAsync();
      } else {
        setCourseTreeCB(JSON.parse(localStorage.getItem('courseTree')));
      }
    } else {
      getCourseTreeAsync();
    }
  }, [setCourseTreeCB]);

  return courseTree;
};
