import React, { useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { http } from '../../../../services/http';
import translations from '../../../../translations/account';
import validateForm from '../../../../utils/validateForm';
import constants from '../../../../constants/account';
import { connectEmailURL, getCurrentUserURL } from '../../../../utils/urls';

const AddEmailForm = ({
  handleSaveEmailSuccess,
  identities,
  setIdentities,
}) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const { email, password } = values;
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = event => {
    event.preventDefault();
    const { id, value } = event.target;
    setValues(prevValues => ({ ...prevValues, [id]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { client, verifyEmailUrl } = constants;
    if (!Object.values(validateForm(values)).length) {
      try {
        await http.post(connectEmailURL(), {
          email,
          password,
          client,
          verificationLink: `${window.location.host}${verifyEmailUrl}`,
        });
        try {
          const { data: updatedUser } = await http.get(getCurrentUserURL());
          localStorage.setItem('user', JSON.stringify(updatedUser));
          setIdentities([...identities, 'email']);
          // eslint-disable-next-line no-empty
        } catch (e) {}
        handleSaveEmailSuccess();
      } catch (e) {
        let errorMessage = translations.errorMessageGeneral;
        if (e.response.status === 409) {
          errorMessage = translations.emailExists;
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          email: errorMessage,
        }));
      }
    } else {
      setErrors(validateForm(values));
    }
  };

  const handleBlur = () => {
    setErrors({
      email: '',
      password: '',
    });
  };

  return (
    <>
      <Row className="w-100">
        <Form className="pl-3 mb-3 w-100" onSubmit={handleSubmit} noValidate>
          <Form.Row>
            <Col sm={3}>
              <Form.Group className="mb-2" controlId="email">
                <Form.Control
                  isInvalid={email && !!errors.email}
                  size="sm"
                  type="email"
                  placeholder="email"
                  onChange={handleChange}
                  className="text-lowercase"
                />
                <Form.Control.Feedback type="invalid" className="text-left">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm={3}>
              <Form.Group className="mb-0" controlId="password">
                <Form.Control
                  isInvalid={password && !!errors.password}
                  size="sm"
                  type="password"
                  placeholder="password"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid" className="text-left">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Button
                variant="primary"
                size="sm"
                type="submit"
                onBlur={handleBlur}
              >
                {translations.save}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Row>
    </>
  );
};

export default AddEmailForm;
