import React, { useState, useEffect } from 'react';
import { Container, Row, Form, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretSquareUp,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { useAlert } from '../../../utils/showAlert';
import { useSortedTable } from '../../../utils/sort';
import SuccessAlert from '../../SuccessAlert';
import ErrorAlert from '../../ErrorAlert';
import InfoAlert from '../../InfoAlert';
import ConfirmationModal from '../ConfirmationModal';
import Header from '../Account/Header';
import translations from '../../../translations/createdStudentsList';
import { userNamesURL, updateUserPasswordsURL } from '../../../utils/urls';
import { http } from '../../../services/http';
import CreateUsers from '../HomeScreen/CreateUsers';
import SortTh from '../SortTh';
import { generatePassword } from '../HomeScreen/CreateUsers/CreateUsersModal';
import AddToGroupModal from '../AddToGroupModal';
import '../GroupTable/index.css';
import './index.css';

const CreatedStudentsList = () => {
  const alert = useAlert();
  const [studentsList, setStudentsList] = useState([]);
  const [checkedStudentsList, setCheckedStudentsList] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isConfirmationDisplayed, setIsConfirmationDisplayed] = useState(false);
  const { creatorKey } = JSON.parse(localStorage.getItem('user'));
  const [visiblePasswordsList, setVisiblePasswordsList] = useState([]);
  const [allPasswordsVisible, setAllPasswordsVisible] = useState(false);
  const [sortState, sortDispatch] = useSortedTable(studentsList, 'createdAt');
  const [modalvisibility, setModalVisibility] = useState(false);

  const errorTitle = translations.error;
  const errorMessage = translations.errorMessageGeneral;
  const successTitle = translations.success;
  const partialSuccessTitle = translations.success;

  const onModalHide = () => {
    setModalVisibility(false);
  };

  const getStudents = async () => {
    try {
      const {
        data: { users },
      } = await http.get(userNamesURL());
      const usersWithDate = users.map(user => {
        return { ...user, createdAt: new Date(user.createdAt) };
      });
      setStudentsList(usersWithDate);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const toggleTopButtonVisibility = () => {
    const topButton = document.getElementById('topButton');
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      topButton.style.display = 'block';
    } else {
      topButton.style.display = 'none';
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', toggleTopButtonVisibility);
    return () => {
      window.removeEventListener('scroll', toggleTopButtonVisibility);
    };
  }, []);

  useEffect(() => {
    const initialSortState = {
      isDescending: true,
      key: 'createdAt',
      table: studentsList,
    };
    sortDispatch(initialSortState);
  }, [sortDispatch, studentsList]);

  const handleSelectAll = () => {
    if (allSelected) {
      setCheckedStudentsList([]);
      setAllSelected(false);
    } else {
      setCheckedStudentsList(studentsList);
      setAllSelected(true);
    }
  };

  const togglePasswordsVisibility = () => {
    if (allPasswordsVisible) {
      setVisiblePasswordsList([]);
      setAllPasswordsVisible(false);
    } else {
      setVisiblePasswordsList(studentsList);
      setAllPasswordsVisible(true);
    }
  };

  const handleCheckboxChange = student => {
    const studentChecked = checkedStudentsList.find(
      checkedStudent => checkedStudent.username === student.username
    );
    if (studentChecked) {
      const updatedCheckedStudentsList = checkedStudentsList.filter(
        checkedStudent => checkedStudent.username !== student.username
      );
      setCheckedStudentsList(updatedCheckedStudentsList);
    } else {
      setCheckedStudentsList([...checkedStudentsList, student]);
    }
  };

  const handlePasswordVisibilityChange = student => {
    const passwordVisible = visiblePasswordsList.find(
      passwordsListStudent => passwordsListStudent.username === student.username
    );
    if (passwordVisible) {
      const updatedPasswordsList = visiblePasswordsList.filter(
        passwordsListStudent =>
          passwordsListStudent.username !== student.username
      );
      setVisiblePasswordsList(updatedPasswordsList);
    } else {
      setVisiblePasswordsList([...visiblePasswordsList, student]);
    }
  };

  const isChecked = student => {
    const studentChecked = checkedStudentsList.find(
      checkedStudent => checkedStudent.username === student.username
    );
    return !!studentChecked;
  };

  const isPasswordVisible = student => {
    const passwordVisible = visiblePasswordsList.find(
      passwordsListStudent => passwordsListStudent.username === student.username
    );
    return !!passwordVisible;
  };

  const closeConfirmationModal = () => {
    setIsConfirmationDisplayed(false);
  };

  const openConfirmationModal = () => {
    setIsConfirmationDisplayed(true);
  };

  const deleteAccounts = async () => {
    closeConfirmationModal();
    const usersToDelete = checkedStudentsList.map(student => {
      return {
        username: student.username,
      };
    });
    try {
      const {
        status,
        data: { successfulUsers },
      } = await http.delete(userNamesURL(), {
        data: {
          users: usersToDelete,
        },
      });
      const updatedStudentsList = studentsList.filter(student => {
        const found = successfulUsers.find(
          user => user.username === student.username
        );
        return !found;
      });
      setStudentsList(updatedStudentsList);
      setCheckedStudentsList([]);
      if (status === 200) {
        alert.success(successTitle, translations.deletedSuccessfully);
      }
      if (status === 207) {
        alert.info(partialSuccessTitle, translations.deletedPartialy);
      }
    } catch (e) {
      alert.error(errorTitle, errorMessage);
    }
  };

  const updatePasswords = async () => {
    const usersToUpdate = checkedStudentsList.map(student => {
      return {
        username: student.username,
        password: generatePassword(),
      };
    });
    try {
      const {
        status,
        data: { successfulUsers },
      } = await http.post(updateUserPasswordsURL(), {
        users: usersToUpdate,
      });
      const updatedStudentsList = studentsList.map(student => {
        const updatedStudent = successfulUsers.find(
          user => user.username === student.username
        );
        if (updatedStudent) {
          return { ...student, password: updatedStudent.password };
        }
        return student;
      });
      setStudentsList(updatedStudentsList);
      setCheckedStudentsList([]);
      if (status === 200) {
        alert.success(successTitle, translations.updatedSuccessfully);
      }
      if (status === 207) {
        alert.info(partialSuccessTitle, translations.updatedPartialy);
      }
    } catch (e) {
      alert.error(errorTitle, errorMessage);
    }
  };

  const addToGroup = () => {
    setModalVisibility(true);
  };

  const renderCreatorKey = () => {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          <div className="mr-2">
            <h5>{translations.yourTeacherCode}</h5>
          </div>
          <div>
            <h2>
              <Badge className="bg-secondary text-white text-uppercase">
                {creatorKey}
              </Badge>
            </h2>
          </div>
        </div>
        <hr />
      </>
    );
  };

  const scrollToTheTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <>
      <SuccessAlert />
      <ErrorAlert />
      <InfoAlert />
      <AddToGroupModal
        visibility={modalvisibility}
        onHide={onModalHide}
        checkedStudents={checkedStudentsList}
      />
      <ConfirmationModal
        show={isConfirmationDisplayed}
        onCancel={closeConfirmationModal}
        onConfirm={deleteAccounts}
      />
      <Container fluid className="content">
        <Header name={translations.createdStudentsAccount} />
        <hr />
        {!!creatorKey && renderCreatorKey()}
        {!!studentsList.length && (
          <Row>
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className="pl-3">
                <p className="m-3">{`${translations.selected} (${checkedStudentsList.length})`}</p>
              </div>
              <div>
                <CreateUsers updateStudentsList={getStudents} />
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ml-2"
                  disabled={!checkedStudentsList.length}
                  onClick={addToGroup}
                >
                  {translations.addToGroup}
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ml-2"
                  disabled={!checkedStudentsList.length}
                  onClick={updatePasswords}
                >
                  {translations.generateNewPasswords}
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ml-2"
                  disabled={!checkedStudentsList.length}
                  onClick={openConfirmationModal}
                >
                  {translations.deleteAccounts}
                </Button>
              </div>
            </div>
          </Row>
        )}
        {!!studentsList.length && (
          <div className="group-table-wrapper w-100">
            <table className="group-table w-100">
              <thead>
                <tr className="group-table-head-row">
                  <th className="student-table-head-cell student-table-cell">
                    <Form.Row>
                      <Form.Check
                        className="pl-4"
                        type="checkbox"
                        onChange={handleSelectAll}
                      />
                    </Form.Row>
                  </th>
                  <SortTh
                    sortKey="username"
                    label={translations.studentId}
                    sortState={sortState}
                    dispatch={sortDispatch}
                  />
                  <th className="student-table-head-cell student-table-cell">
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={allPasswordsVisible ? faEye : faEyeSlash}
                      onClick={togglePasswordsVisibility}
                    />
                    {translations.password}
                  </th>
                  <SortTh
                    sortKey="createdAt"
                    label={translations.creationDate}
                    sortState={sortState}
                    dispatch={sortDispatch}
                  />
                </tr>
              </thead>
              <tbody>
                {sortState.table.map(student => (
                  <tr key={student.username} className="group-table-body-row">
                    <td className="align-middle student-table-cell">
                      <Form.Row>
                        <Form.Check
                          className="pl-4"
                          type="checkbox"
                          checked={isChecked(student)}
                          onChange={() => handleCheckboxChange(student)}
                        />
                      </Form.Row>
                    </td>
                    <td
                      id={`user-name-cell-${student.username}`}
                      className="student-table-cell align-middle"
                    >
                      {student.username}
                    </td>
                    <td
                      className={`student-table-cell align-middle ${
                        isPasswordVisible(student) ? '' : 'disc'
                      }`}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={isPasswordVisible(student) ? faEye : faEyeSlash}
                        onClick={() => {
                          handlePasswordVisibilityChange(student);
                        }}
                      />
                      {student.password}
                    </td>
                    <td className="student-table-cell align-middle">
                      {student.createdAt.toLocaleDateString('sv-SE', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <Row>
          <CreateUsers updateStudentsList={getStudents} />
        </Row>
      </Container>
      <FontAwesomeIcon
        id="topButton"
        className="toTheTopButton"
        icon={faCaretSquareUp}
        onClick={scrollToTheTop}
      />
    </>
  );
};

export default CreatedStudentsList;
