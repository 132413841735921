import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Header = ({ name }) => {
  return (
    <Row className="pb-1">
      <Col>
        <h3 className="mt-4">{name}</h3>
      </Col>
    </Row>
  );
};

export default Header;
