/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { useCourseTree } from '../../../hooks/useCourseTree';
import { useCustomAccessTrees } from '../../../hooks/useCustomAccessTrees';
import isCourseTreeEmpty from '../../../utils/isCourseTreeEmpty';
import { getScoreAllExercises } from '../../../utils/reshapeUserWithExercises';
import LoadingSpinner from '../LoadingSpinner';
import { getGroup } from '../GroupContent/duck';
import './index.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const UserTimeline = ({ id, groupId }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);
  const [columnCount, setColumnCount] = useState({});
  const [userScoreAllExercises, setUserScoreAllExercises] = useState([]);
  const usersWithExercises = useSelector(
    state => state.groupContent.usersWithExercises
  );
  const courseTree = useCourseTree();
  const customAccessTrees = useCustomAccessTrees();

  useEffect(() => {
    if (usersWithExercises.length && !isCourseTreeEmpty(courseTree)) {
      setUserScoreAllExercises(
        getScoreAllExercises(
          usersWithExercises.find(notysingUser => notysingUser.id === id),
          courseTree
        )
      );
      setUser(usersWithExercises.find(notysingUser => notysingUser.id === id));
    }
  }, [courseTree, usersWithExercises]);

  useEffect(() => {
    if (!usersWithExercises.length && !isCourseTreeEmpty(courseTree)) {
      dispatch(getGroup(groupId, courseTree, customAccessTrees));
    }
  }, [usersWithExercises, courseTree]);

  useEffect(() => {
    if (!usersWithExercises.length && !isCourseTreeEmpty(courseTree)) {
      dispatch(getGroup(groupId, courseTree, customAccessTrees));
    }

    setColumnCount(countColumnData(userScoreAllExercises, ['answers', 'url']));

  }, [userScoreAllExercises]);
  console.log('userScoreAllExercises', userScoreAllExercises);

  function countColumnData(array, columns) {
    let dataCount = {};
    for (const element of array) {
      for (const column of columns) {
        if (element[column]) {
          if (dataCount[column]) {
            dataCount[column]++;
          } else {
            dataCount[column] = 1;
          }
        }
      }
    }
    return dataCount;
  }

  function finishedFormatter(cell, row) {
    return cell ? '✅' : '❌';
  }

  function lessonExerciseFormatter(cell, row) {
    if (!cell) { return; }
    return `<span title="${getCourseName(row['id'])}">${cell}</span>`;
  }

  function answersFormatter(cell, row) {
    if (!cell || !row['url']) { return; }
    return `<a href="${row['url']}?data=${encodeURIComponent(JSON.stringify(cell))}" target="_blank">inspect</a>`;
  }

  function timestampFormatter(cell, row) {
    return `<span title="${cell}">${new Date(cell).toLocaleString("sv-SE", {
      timeZone: "Europe/Stockholm"
    })} (CET/CEST)</span>`;
  }

  function getCourseName(targetId) {
    for (const courseId in customAccessTrees) {
      for (const course of customAccessTrees[courseId]) {
        // Check if the course ID matches
        if (course.id === targetId) {
          return course.shortName || course.name;
        }

        // Check lessons
        if (course.lessons) {
          for (const lesson of course.lessons) {
            if (lesson.id === targetId) {
              return course.shortName || course.name;
            }

            // Check exercises
            if (lesson.exercises) {
              for (const exercise of lesson.exercises) {
                if (exercise.id === targetId) {
                  return course.shortName || course.name;
                }
              }
            }
          }
        }
      }
    }
    return 'Notysing'; // If not found in customAccessTrees, asume Notysing app
  }

  const options = {
    noDataText: 'Loading... (or user list is empty)',
    sizePerPage: 50,  // which size per page you want to locate as default
    sizePerPageList: [{
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '500', value: 500
    },
    {
      text: 'All', value: userScoreAllExercises.length
    }],
    paginationShowsTotal: true
  };

  const updatedResultsArray = userScoreAllExercises.map(item => ({
    ...item,
    courseName: getCourseName(item.id)
  }));

  return (
    <div className="ml-3 d-flex flex-column">
      {user && <div className="m-3">{user.alias} - {user.email}</div>}
      <LoadingSpinner />
      <BootstrapTable data={updatedResultsArray} options={options} pagination striped hover>
        <TableHeaderColumn dataField="key" isKey hidden>Key</TableHeaderColumn>
        <TableHeaderColumn dataField="finished" width="50" dataFormat={finishedFormatter}>FINISHED</TableHeaderColumn>
        <TableHeaderColumn dataField="courseName">SOURCE</TableHeaderColumn>
        <TableHeaderColumn dataField="lesson" dataFormat={lessonExerciseFormatter}>LESSON</TableHeaderColumn>
        <TableHeaderColumn dataField="exercise" dataFormat={lessonExerciseFormatter}>EXERCISE</TableHeaderColumn>
        <TableHeaderColumn dataField="score" width="80">SCORE</TableHeaderColumn>
        <TableHeaderColumn dataField="maxScore" width="100">MAXSCORE</TableHeaderColumn>
        <TableHeaderColumn dataField="timestamp" dataFormat={timestampFormatter}>TIME</TableHeaderColumn>
        {columnCount['answers'] > 0 && columnCount['url'] > 0
          && <TableHeaderColumn dataField="answers" dataFormat={answersFormatter}>ANSWERS</TableHeaderColumn>
        }
      </BootstrapTable>
    </div>
  );
};

export default UserTimeline;
