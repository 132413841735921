import React from 'react';
import { Row } from 'react-bootstrap';

const renderParagraphs = paragraphs => {
  return paragraphs.map(paragraph => (
    <Row className="w-100 pl-3" key={Math.random()}>
      <p>{paragraph}</p>
    </Row>
  ));
};

const Section = ({
  name,
  description,
  multiParagraphDescription,
  children,
}) => {
  return (
    <>
      <h5 className="font-weight-bold">{name}</h5>
      <Row className="w-100 pl-3">{description && <p>{description}</p>}</Row>
      {multiParagraphDescription && renderParagraphs(multiParagraphDescription)}
      {children}
    </>
  );
};

export default Section;
