import React from 'react';
import { Link } from 'react-router-dom';

const BackToPage = ({ to, text }) => {
  return (
    <div className="text-center">
      <Link to={to}>{text}</Link>
    </div>
  );
};

export default BackToPage;
