import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import translations from '../../../translations/groupNameModal';

const GroupNameModal = ({ groupName, show, onHide, onSave }) => {
  const [group, setGroup] = useState(groupName || '');
  const formControlRef = useRef(null);

  useEffect(() => {
    if (formControlRef.current) {
      formControlRef.current.focus();
    }
  }, []);

  const handleChange = event => {
    const { value } = event.target;
    setGroup(value);
  };
  const handleSubmit = event => {
    event.preventDefault();
    onSave(group);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton id="contained-modal-title-vcenter">
        {groupName ? translations.updateGroup : translations.addNewGroup}
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} data-testid="group-name-modal-form">
          <Form.Group>
            <Form.Control
              data-testid="group-name-modal-form-control"
              ref={formControlRef}
              value={group}
              type="text"
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {translations.close}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {translations.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupNameModal;
