import React from 'react';
import { ListGroup } from 'react-bootstrap';

import translations from '../../../translations/lessonsList';

const LessonsList = ({ lessons, courseName }) => {
  return (
    <>
      <ListGroup className="lessons-list-group">
        <ListGroup.Item
          variant="secondary"
          key={courseName}
          className="lessons-list-group-item bold"
        >
          {courseName}
        </ListGroup.Item>
        {Object.keys(lessons).map(lessonId => {
          let lessonScore = translations.failed;
          if (lessons[lessonId].finished) {
            lessonScore = lessons[lessonId].percentage
              ? `${lessons[lessonId].percentage}%`
              : translations.passed;
          }
          return (
            <ListGroup.Item
              variant="secondary"
              key={lessonId}
              className="lessons-list-group-item"
            >
              {`${lessons[lessonId].name}: ${lessonScore}`}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
};

export default LessonsList;
