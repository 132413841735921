import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import CreateAccount from './components/CreateAccount';
import Home from './components/Home';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ResetPasswordFromAccount from './components/ResetPassword/ResetPasswordFromAccount';
import VerifyEmail from './components/VerifyEmail';
import GoogleClassroomCallback from './components/Home/HomeScreen/ImportUsers/GoogleClassroomCallback';
import { http } from './services/http';
import { getCurrentUserURL } from './utils/urls';
import importUsersModalConstants from './constants/importUsersModal';
import LoginCallback from './components/LoginCallback';
import loginConstants from './constants/login';

export const Routes = () => (
  <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="/request-reset-password">
      <ForgotPassword />
    </Route>
    <Route path="/create-account">
      <CreateAccount />
    </Route>
    <Route path="/reset-password">
      <ResetPassword />
    </Route>
    <PrivateRoute
      path={[
        importUsersModalConstants.googleClassroomCoursesCallbackRoute,
        `${importUsersModalConstants.googleClassroomCourseStudentsCallbackRoute}/:id`,
      ]}
    >
      <GoogleClassroomCallback />
    </PrivateRoute>
    <Route path={loginConstants.googleLoginCallbackRoute}>
      <LoginCallback />
    </Route>
    <PrivateRoute exact path="/">
      <Home />
    </PrivateRoute>
    <PrivateRoute exact path="/groups/:id">
      <Home />
    </PrivateRoute>
    <PrivateRoute exact path="/account">
      <Home />
    </PrivateRoute>
    <Route path="/verify-email">
      <VerifyEmail />
    </Route>
    <PrivateRoute exact path="/created-accounts">
      <Home />
    </PrivateRoute>
    <PrivateRoute exact path="/reset-password-from-account">
      <ResetPasswordFromAccount />
    </PrivateRoute>
    <PrivateRoute exact path="/user-timeline/:gId/:studentId/:displayName">
      <Home />
    </PrivateRoute>
    <Redirect to="/" />
  </Switch>
);

const App = () => {
  useEffect(() => {
    const fetchCurrentUserAsync = async () => {
      try {
        const { data: user } = await http.get(getCurrentUserURL());
        localStorage.setItem('user', JSON.stringify(user));
      } catch (e) {
        localStorage.removeItem('user');
      }
    };
    fetchCurrentUserAsync();
  }, []);

  return (
    <>
      <Router>
        <Routes />
      </Router>
    </>
  );
};

export default App;
