import React from 'react';

import './index.css';
import LessonsListTooltip from '../LessonsListTooltip';
import flattenSingleLevelArray from '../../../utils/flattenSingleLevelArray';

const GroupTableElement = ({
  course: { finished, started, percentage, lessons },
  allCourses,
  userId,
  courseName,
  courseId,
  lesson,
  detailedColumns
}) => {
  const allLessonsPerCourse = allCourses.map(course => course.lessons);
  const allLessons = flattenSingleLevelArray(allLessonsPerCourse);
  const lessonsWithName = { ...lessons };
  Object.keys(lessons).forEach(id => {
    const aLesson = allLessons.filter(lesson => String(lesson.id) === id);
    lessonsWithName[id].name = aLesson[0].name;
  });
  let className = '';
  if (finished) {
    className = 'group-table-element-finished bg-success my-auto mx-auto';
  } else if (started) {
    className = 'group-table-element-started bg-warning';
  }

  if (lesson && lessonsWithName[lesson]) {
    if (detailedColumns) {
      return (
        <>
          <td
            key={"lesson-" + userId + "-" + lesson + "-percentage"}
            id={"lesson-" + userId + "-" + lesson + "-percentage"}
            className="group-table-body-cell align-middle"
            style={{ backgroundColor: "#F4F3F0" }}
          >
            {lessonsWithName[lesson].percentage > 0 &&
              <div className='group-table-element-lesson'>
                {lessonsWithName[lesson].percentage}%
              </div>
            }
          </td>
          <td
            key={"lesson-" + userId + "-" + lesson + "-score"}
            id={"lesson-" + userId + "-" + lesson + "-score"}
            className="group-table-body-cell align-middle"
          >
            {lessonsWithName[lesson].percentage > 0 &&
              <div className='group-table-element-lesson'>
                {lessonsWithName[lesson].score}
              </div>
            }
          </td>
          <td
            key={"lesson-" + userId + "-" + lesson + "-maxScore"}
            id={"lesson-" + userId + "-" + lesson + "-maxScore"}
            className="group-table-body-cell align-middle"
          >
            {lessonsWithName[lesson].percentage > 0 &&
              <div className='group-table-element-lesson'>
                {lessonsWithName[lesson].maxScore}
              </div>
            }
          </td>
        </>
      )
    } else {
      return (
        <td
          key={"lesson-" + userId + "-" + lesson}
          id={"lesson-" + userId + "-" + lesson}
          className="group-table-body-cell align-middle"
        >
          {lessonsWithName[lesson].percentage > 0 &&
            <div className='group-table-element-lesson'>
              {lessonsWithName[lesson].percentage}% <br />
              <span className='group-table-element-lesson-score'>
                ({lessonsWithName[lesson].score} / {lessonsWithName[lesson].maxScore})
              </span>
            </div>
          }
        </td>
      )
    }
  }

  return (
    <td
      key={"course-" + userId + "-" + courseId}
      id={"course-" + userId + "-" + courseId}
      className="group-table-body-cell align-middle"
    >
      {
        finished || started ? (
          <LessonsListTooltip
            lessons={lessonsWithName}
            courseName={courseName}
            placement="top"
          >
            <div className={className}>{percentage || ''}</div>
          </LessonsListTooltip>
        ) : (
          <div data-testid="empty-div" className={className} />
        )
      }
    </td >
  );
};

export default GroupTableElement;
