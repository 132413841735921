/*
 * action types
 */

export const SET_COURSES = 'SET_COURSES';
export const SET_STUDENTS = 'SET_STUDENTS';
export const SET_STEP = 'SET_STEP';

/*
 * action creators
 */

export const setStep = step => {
  return {
    type: SET_STEP,
    step,
  };
};

export const setCourses = courses => {
  return {
    type: SET_COURSES,
    courses,
  };
};

export const setStudents = students => {
  return {
    type: SET_STUDENTS,
    students,
  };
};

/*
 * Reducers
 */

const initialState = {
  step: 0,
  courses: [],
  students: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case SET_COURSES:
      return {
        ...state,
        courses: action.courses,
      };
    case SET_STUDENTS:
      return {
        ...state,
        students: action.students,
      };
    default:
      return state;
  }
};

export default reducer;
