import axios from 'axios';

import { API } from '../serverConfig';

export const http = axios.create({
  withCredentials: true,
  baseURL: API,
});

export const httpRes = axios.create({ baseURL: window.location.origin });
