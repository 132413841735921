import { http } from '../../../services/http';
import {
  createGroupURL,
  updateGroupURL,
  deleteGroupURL,
  fetchGroupsURL,
} from '../../../utils/urls';
import { setError } from '../../ErrorNotification/duck';

/*
 * action types
 */

export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const SET_GROUPS = 'SET_GROUPS';

/*
 * action creators
 */

export const addGroup = group => {
  return {
    type: ADD_GROUP,
    group,
  };
};

export const updateGroup = (name, id) => {
  return {
    type: UPDATE_GROUP,
    name,
    id,
  };
};

export const deleteGroup = id => {
  return {
    type: DELETE_GROUP,
    id,
  };
};

export const setGroups = userGroups => {
  return {
    type: SET_GROUPS,
    userGroups,
  };
};

export const createGroup = name => {
  return async dispatch => {
    try {
      const { data } = await http.post(createGroupURL(), { name });

      dispatch(addGroup(data));
    } catch (e) {
      dispatch(setError(e));
    }
  };
};

export const editGroup = (name, id) => {
  return async dispatch => {
    try {
      await http.patch(updateGroupURL(id), { name });

      dispatch(updateGroup(name, id));
    } catch (e) {
      dispatch(setError(e));
    }
  };
};

export const removeGroup = id => {
  return async dispatch => {
    try {
      await http.delete(deleteGroupURL(id));

      dispatch(deleteGroup(id));
    } catch (e) {
      dispatch(setError(e));
    }
  };
};

export const fetchGroups = () => {
  return async dispatch => {
    try {
      const { data } = await http.get(fetchGroupsURL());

      dispatch(setGroups(data.groups));
    } catch (e) {
      dispatch(setError(e));
    }
  };
};

/*
 * Reducer
 */

const initialState = [];

const groups = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GROUP:
      return state.concat(action.group);
    case UPDATE_GROUP:
      return state.map(group =>
        action.id === group.id
          ? {
              ...group,
              name: action.name,
            }
          : group
      );
    case DELETE_GROUP:
      return state.filter(group => group.id !== action.id);
    case SET_GROUPS:
      return action.userGroups;
    default:
      return state;
  }
};

export default groups;
