/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import { getResourcesRootURL, deleteUserURL } from '../../../utils/urls';
import GroupTableElement from '../GroupTableElement';
import translations from '../../../translations/groupTable';
import { http } from '../../../services/http';
import { getGroup } from '../GroupContent/duck';
import getRowUserId from '../../../utils/getRowUserId';
import { setError } from '../../ErrorNotification/duck';
import ConfirmationModal from '../ConfirmationModal';
import isCourseTreeEmpty from '../../../utils/isCourseTreeEmpty';
import CourseIconTooltip from '../CourseIconTooltip';

const GroupTable = ({ gId, courses, columns = 'courses', detailedColumns }) => {
  const isLoadingSpinnerVisible = useSelector(
    state => state.loadingSpinnerReducer.isVisible
  );

  const history = useHistory();
  const [showIconId, setShowIconId] = useState('');
  const [deletingId, setDeletingId] = useState('');
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const users = useSelector(state => state.groupContent.users);

  const usersWithExercises = useSelector(
    state => state.groupContent.usersWithExercises
  );

  const dispatch = useDispatch();

  const handleDeleteUser = () => {
    setConfirmationModalShow(true);
    setDeletingId(showIconId);
  };

  const handleConfirmDelete = async () => {
    try {
      await http.delete(deleteUserURL(gId, deletingId));
      setDeletingId('');
      setConfirmationModalShow(false);
      dispatch(getGroup(gId, courses));
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const handleCancelDelete = () => {
    setConfirmationModalShow(false);
    setDeletingId('');
  };

  const handleMouseEnter = event => {
    event.persist();
    const { id } = event.target;
    setShowIconId(getRowUserId(id));
  };

  const handleMouseLeave = event => {
    event.persist();
    setShowIconId('');
  };

  const handleUserClick = event => {
    const { id } = event.target;
    const studenId = id.split('-').pop();
    history.push(`/user-timeline/${gId}/${studenId}/${users[studenId].alias}`);
  };

  const sortedUsers = Object.keys(users).sort((a, b) =>
    users[a].alias > users[b].alias ? 1 : -1
  );

  function getLatestTimestamp(users, userId) {
    const user = users.find(user => user.id === userId);

    if (!user || !user.exercises || !user.exercises.length) {
      return null;
    }

    const latestExercise = user.exercises
      .filter(exercise => exercise.timestamp)
      .reduce(
        (latest, current) => {
          if (!latest) return current; // Initialize with the first exercise
          return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
        },
        null // default value
      ) || {}; // fallback to an empty object if no valid exercise is found

    return new Date(latestExercise.timestamp).toLocaleString("sv-SE", {
      timeZone: "Europe/Stockholm"
    });
  }

  return (
    <>
      {Object.keys(users).length ? (
        <div className={"group-table-wrapper " + (isLoadingSpinnerVisible ? 'loading' : '')}>
          <table className="group-table">
            <thead>
              <tr className="group-table-head-row">
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th key="group-table-head-cell" className="group-table-head-cell" />
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th key="group-table-first-col" className="group-table-first-col group-table-head-cell" />
                {
                  !isCourseTreeEmpty(courses)
                    ? courses.map(course => (
                      <Fragment key={"fragment-" + course.id}>
                        <th key={"group-table-head-cell-" + course.id} className="group-table-head-cell">
                          <CourseIconTooltip
                            courseName={course.name}
                            placement="top"
                          >
                            <img
                              className="group-table-icon"
                              alt={course.name}
                              src={`${getResourcesRootURL()}/${course.icon}`}
                            />
                          </CourseIconTooltip>
                        </th>

                        {columns === 'lessons' &&
                          <>
                            {course.lessons.map((lesson, index) => {
                              if (lesson.id) {
                                if (detailedColumns) {
                                  return (
                                    <>
                                      <th key={"lessons-" + lesson.id + "-percentage"} className="group-table-head-cell">
                                        Lesson {index + 1} percentage
                                      </th>
                                      <th key={"lessons-" + lesson.id + "-score"} className="group-table-head-cell">
                                        Lesson {index + 1} score
                                      </th>
                                      <th key={"lessons-" + lesson.id + "-maxScore"} className="group-table-head-cell">
                                        Lesson {index + 1} max score
                                      </th>
                                    </>
                                  )
                                } else {
                                  return (
                                    <th key={"lessons-" + lesson.id} className="group-table-head-cell">
                                      <CourseIconTooltip
                                        courseName={lesson.name}
                                        placement="top"
                                      >
                                        <div className='group-table-lesson-number'>{index + 1}</div>
                                      </CourseIconTooltip>
                                    </th>
                                  )
                                }
                              }
                              return false;
                            })}
                            <th className="group-table-head-cell group-table-element-timestamp-header">
                              <CourseIconTooltip
                                courseName="Note that this is the latest action from any admission test, or from the Notysing app. Make sure to investigate!"
                                placement="top"
                              >
                                <div>Latest action</div>
                              </CourseIconTooltip>
                            </th>
                          </>
                        }
                      </Fragment>


                    ))
                    : null}
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map(userId => (
                <tr key={"group-table-body-row" + userId} className="group-table-body-row">
                  <td
                    key="group-table-body-delete-icon-cell"
                    className="group-table-body-delete-icon-cell align-middle"
                    id={`delete-icon-cell-${userId}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {showIconId.endsWith(userId) ? (
                      <FontAwesomeIcon
                        data-testid={`delete-icon-${userId}`}
                        id={`delete-icon-${userId}`}
                        icon={faTrashAlt}
                        onClick={handleDeleteUser}
                      />
                    ) : (
                      <div className="group-table-empty-div" />
                    )}
                  </td>
                  <td
                    data-testid={`user-alias-cell-${userId}`}
                    key={`user-alias-cell-${userId}`}
                    id={`user-alias-cell-${userId}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleUserClick}
                    className="user-alias-cell group-table-body-first-cell align-middle"
                  >
                    {users[userId].alias}
                  </td>
                  {!isCourseTreeEmpty(courses)
                    ? courses.map(course => {
                      if (users[userId].courses[course.id]) {
                        return (
                          <Fragment key={"fragment-" + userId + "-" + course.id}>
                            <GroupTableElement
                              key={"GroupTableElement-" + userId + "-" + course.id}
                              allCourses={courses}
                              courseName={course.name}
                              course={users[userId].courses[course.id]}
                              courseId={course.id}
                              userId={userId}
                            />

                            {columns === 'lessons' &&
                              <>
                                {course.lessons.map(lesson => {
                                  if (lesson.id) {
                                    return (
                                      <GroupTableElement
                                        key={"GroupTableElement-" + userId + "-" + lesson.id}
                                        allCourses={courses}
                                        courseName={course.name}
                                        course={users[userId].courses[course.id]}
                                        lesson={lesson.id}
                                        courseId={course.id}
                                        userId={userId}
                                        detailedColumns={detailedColumns}
                                      />
                                    )
                                  }
                                  return false;
                                })}
                                <td className="group-table-body-cell align-middle group-table-element-lesson-score">{getLatestTimestamp(usersWithExercises, userId)}</td>
                              </>
                            }
                          </Fragment>
                        )
                      } else {
                        return null;
                      }
                    })
                    : null}
                </tr >
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Row>
          <p className="ml-4">{translations.addAUser}</p>
        </Row>
      )}
      {confirmationModalShow ? (
        <ConfirmationModal
          show={confirmationModalShow}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
    </>
  );
};

export default GroupTable;
