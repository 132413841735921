import React, { useState, useLayoutEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import translations from '../../../../../translations/createUsersModal';
import CreatorKeyModalBody from '../CreatorKeyModalBody';
import {
  getCreatorKeysURL,
  setCreatorKeyURL,
  userNamesURL,
  addUsersToGroupURL,
  getCurrentUserURL,
} from '../../../../../utils/urls';
import { http } from '../../../../../services/http';
import { setError } from '../../../../ErrorNotification/duck';
import GroupSelectFormModalBody from '../../ImportUsers/GroupSelectFormModalBody';
import { createGroup } from '../../../GroupsDropdown/duck';
import UsersListFormModalBody from '../../ImportUsers/UsersListFormModalBody';
import courseAndGroupSelectConstants from '../../../../../constants/courseAndGroupSelectFormModalBody';
import constants from '../../../../../constants/createUsersModal';
import getArrayFromList from '../../../../../utils/parseList';
import SuccessMessage from '../../SuccessMessage';
import wordList from './wordList';

export const generatePassword = () => {
  const passwordArray = [];
  const getRandomIntInclusive = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  const words = getArrayFromList(wordList);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < constants.wordsInPassword; i++) {
    passwordArray.push(words[getRandomIntInclusive(0, words.length - 1)]);
  }
  passwordArray.push(
    `${getRandomIntInclusive(0, 9)}${getRandomIntInclusive(0, 8)}`
  );
  const shuffledPasswordArray = passwordArray.sort((a, b) => 0.5 - Math.random());
  return shuffledPasswordArray.join('-');
};

const CreateUsersModal = ({ step, setStep, show, onHide }) => {
  const dispatch = useDispatch();
  const groups = useSelector(state => state.groups);
  const group = useSelector(state => state.courseAndGroupSelectReducer.group);
  const newGroup = useSelector(
    state => state.courseAndGroupSelectReducer.newGroup
  );
  const [creatorKey, setCreatorKey] = useState('');
  const [creatorKeys, setCreatorKeys] = useState([]);
  const [usersList, setUsersList] = useState('');
  const [isLoading, setLoading] = useState(false);

  const getCreatorKeys = async () => {
    try {
      const { status, data } = await http.get(getCreatorKeysURL());
      if (status === 200) {
        setCreatorKey(data.pop());
        setCreatorKeys(data);
      }
    } catch (e) {
      onHide();
      dispatch(setError(e));
    }
  };

  useLayoutEffect(() => {
    getCreatorKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateNewCode = () => {
    if (creatorKeys.length) {
      setCreatorKey(creatorKeys.pop());
    } else {
      getCreatorKeys();
    }
  };

  const getErrorList = failedUsers => {
    const errorMessages = failedUsers.map(
      faileduser => `• ${faileduser.username}: ${faileduser.message}`
    );
    return errorMessages.join('\n');
  };

  const handleAcceptCreatorKey = async () => {
    const acceptKeyCheckbox = document.getElementById('acceptKeyCheckbox');
    const acceptKeyCheckboxContainer = document.getElementById(
      'acceptKeyCheckboxContainer'
    );
    if (acceptKeyCheckbox.checked) {
      try {
        const { status } = await http.post(setCreatorKeyURL(), {
          creatorKey,
        });
        if (status === 200) {
          const { data: user } = await http.get(getCurrentUserURL());
          localStorage.setItem('user', JSON.stringify(user));
          setStep(2);
        }
      } catch (e) {
        if (e.response.status === 400) {
          e.response = {
            ...e.response,
            message: translations.codeExists,
          };
        }
        onHide();
        dispatch(setError(e));
      }
    } else {
      acceptKeyCheckboxContainer.classList.add('accept-warning');
    }
  };

  const handleChooseGroup = () => {
    if (group !== 'add') {
      setStep(3);
    } else {
      dispatch(createGroup(newGroup));
      setStep(3);
    }
  };

  const handleUsersListChange = event => {
    const { value } = event.target;
    setUsersList(value);
  };

  const handleCreateUsers = async () => {
    const usersToCreate = getArrayFromList(usersList).map(user => {
      return {
        username: user[0],
        password: user[1] ? user[1].trim() : generatePassword(),
      };
    });
    try {
      setLoading(true);
      const {
        status,
        data: { successfulUsers, failedUsers },
      } = await http.post(userNamesURL(), {
        users: usersToCreate,
      });
      if (group !== courseAndGroupSelectConstants.addLaterId) {
        let id = '';
        if (newGroup) {
          id = groups.filter(gr => gr.name === newGroup)[0].id;
        } else {
          id = group;
        }
        const addToGroupUsers = successfulUsers.map(user => {
          return {
            id: user.username,
            alias: user.username,
            provider: 'username',
          };
        });
        const addToGroupResponse = await http.patch(addUsersToGroupURL(id), {
          users: addToGroupUsers,
        });
        if (addToGroupResponse.status === 207) {
          const error = new Error();
          error.response = {
            status: addToGroupResponse.status,
            message: translations.addToGroupFailed,
          };
          error.title = translations.partialSuccess;
          onHide();
          dispatch(setError(error));
        }
      }
      setLoading(false);
      if (status === 200) {
        setStep(4);
      }
      if (status === 207) {
        const error = new Error();
        error.response = {
          status,
          message: translations.someUsersFailedToCreate.concat(
            '\n',
            getErrorList(failedUsers)
          ),
        };
        error.title = translations.partialSuccess;
        onHide();
        dispatch(setError(error));
      }
    } catch (e) {
      setLoading(false);
      e.response = {
        ...e.response,
        message: translations.someUsersFailedToCreate.concat(
          '\n',
          getErrorList(e.response.data.failedUsers)
        ),
      };
      onHide();
      dispatch(setError(e));
    }
  };

  let modalHeaderText;
  let modalBody;
  let modalFooter;

  switch (step) {
    case 1:
      modalHeaderText = translations.teacherCode;
      modalBody = (
        <CreatorKeyModalBody
          creatorKey={creatorKey}
          generateNewCode={generateNewCode}
        />
      );
      modalFooter = (
        <>
          <Button variant="secondary" onClick={onHide}>
            {translations.cancel}
          </Button>
          <Button variant="primary" onClick={handleAcceptCreatorKey}>
            {translations.next}
          </Button>
        </>
      );
      break;
    case 2:
      modalHeaderText = translations.chooseGroup;
      modalBody = (
        <GroupSelectFormModalBody
          extraOptions={[
            {
              id: courseAndGroupSelectConstants.add,
              name: courseAndGroupSelectConstants.newGroup,
            },
            {
              id: courseAndGroupSelectConstants.addLaterId,
              name: courseAndGroupSelectConstants.addLaterName,
            },
          ]}
          labelText={translations.addToGroup}
        />
      );
      modalFooter = (
        <>
          <Button variant="secondary" onClick={onHide}>
            {translations.cancel}
          </Button>
          <Button
            variant="primary"
            onClick={handleChooseGroup}
            disabled={(group === '' || group === 'add') && newGroup === ''}
          >
            {translations.next}
          </Button>
        </>
      );
      break;
    case 3:
      modalHeaderText = translations.createAccounts;
      modalBody = (
        <UsersListFormModalBody
          onChange={handleUsersListChange}
          usersList={usersList}
          label={translations.pasteUsers}
          placeholder={translations.userListPlaceholder}
        />
      );
      modalFooter = (
        <>
          <Button variant="secondary" onClick={onHide}>
            {translations.cancel}
          </Button>
          <Button
            variant="success"
            onClick={handleCreateUsers}
            disabled={isLoading}
          >
            {isLoading ? translations.creating : translations.create}
          </Button>
        </>
      );
      break;
    case 4:
      modalHeaderText = translations.usersCreated;
      modalBody = (
        <SuccessMessage
          title={translations.usersCreatedSuccessfully}
          message={translations.manageUsers}
        />
      );
      modalFooter = (
        <Button variant="success" onClick={onHide}>
          {translations.ok}
        </Button>
      );
      break;
    default:
      modalHeaderText = <></>;
      modalBody = <></>;
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton id="contained-modal-title-vcenter">
        {modalHeaderText}
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      <Modal.Footer>{modalFooter}</Modal.Footer>
    </Modal>
  );
};

export default CreateUsersModal;
