import createObjectFromEntries from './createObjectFromEntries';

const reshapeUsers = (groupData, courseTree, customAccessTrees) => {
  const { userAliases, users } = groupData;
  const usersWithAliases = users.map(user => {
    return {
      ...user,
      alias: userAliases.find(alias => alias.userId === user.id).alias,
    };
  });

  let mergedTrees = courseTree;

  for (const customAccessTree in customAccessTrees) {
    mergedTrees = mergedTrees.concat(customAccessTrees[customAccessTree]);
  }

  const reshapedUsers = {};
  usersWithAliases.forEach(user => {
    let coursesIds = mergedTrees.map(course => course.id);
    const coursesEntries = coursesIds.map(id => {
      return [
        id,
        {
          finished: false,
          started: false,
          percentage: 0,
          lessons: {},
        },
      ];
    });
    const reshapedCourses = createObjectFromEntries(coursesEntries);
    mergedTrees.forEach(course => {
      const lessonIds = course.lessons.map(lesson => lesson.id);
      const userCourseLessonsEntries = lessonIds.map(id => {
        return [
          id,
          {
            finished: false,
            score: 0,
            maxScore: 0,
            percentage: 0,
          },
        ];
      });
      const userCourseLessons = createObjectFromEntries(
        userCourseLessonsEntries
      );

      if (
        user.notysing &&
        user.notysing.lessons &&
        user.notysing.lessons.length
      ) {
        const userLessons = user.notysing.lessons;
        userLessons.forEach(userLesson => {
          if (lessonIds.includes(userLesson.id)) {
            if (userLesson.finished) {
              userCourseLessons[userLesson.id].finished = true;

              if (userLesson.timestamp && userLesson.score > 0) {  // Ignore 0 scores
                const previousTimestamp = userCourseLessons[userLesson.id].timestamp || 0;
                const newTimestamp = new Date(userLesson.timestamp).getTime();

                if (newTimestamp > previousTimestamp) {  // Update only if newer
                  userCourseLessons[userLesson.id].score = userLesson.score;
                  userCourseLessons[userLesson.id].maxScore = userLesson.maxScore;
                  userCourseLessons[userLesson.id].timestamp = newTimestamp; // Store latest timestamp

                  if (userLesson.maxScore) {
                    userCourseLessons[userLesson.id].percentage = Math.round(
                      (userLesson.score / userLesson.maxScore) * 100
                    );
                  }
                }
              }
            }
          }
        });
        reshapedCourses[course.id].lessons = userCourseLessons;
        reshapedCourses[course.id].finished =
          Object.values(reshapedCourses[course.id].lessons).filter(
            lesson => lesson.finished
          ).length === course.lessons.length;
        reshapedCourses[course.id].started =
          Object.values(reshapedCourses[course.id].lessons).some(
            lesson => lesson.finished === true
          ) && !reshapedCourses[course.id].finished;

        if (reshapedCourses[course.id].finished) {
          const courseLessonsPercentages = Object.values(
            reshapedCourses[course.id].lessons
          ).reduce(
            (a, { percentage }) => (percentage ? [...a, percentage] : a),
            []
          );
          reshapedCourses[course.id].percentage =
            courseLessonsPercentages.length > 0
              ? Math.round(
                courseLessonsPercentages.reduce((a, b) => a + b, 0) /
                courseLessonsPercentages.length
              )
              : 0;
        }
      }
    });
    reshapedUsers[user.id] = {
      alias: user.alias,
      courses: reshapedCourses,
    };
  });

  return reshapedUsers;
};

export default reshapeUsers;
