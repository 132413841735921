import { useEffect, useReducer } from 'react';
import { ascend, descend, prop, sort } from 'ramda';

const sortedTableReducer = (oldState, newState) => {
  const { isDescending, key, table } = { ...oldState, ...newState };
  const direction = isDescending ? descend : ascend;
  const sortFunc = sort(direction(prop(key)));
  return { isDescending, key, table: sortFunc(table) };
};

// eslint-disable-next-line import/prefer-default-export
export const useSortedTable = (table, key, isDescending = true) => {
  const initialState = { isDescending, key, table };
  const [state, dispatch] = useReducer(sortedTableReducer, initialState);
  useEffect(() => {
    dispatch({});
  }, []);
  return [state, dispatch];
};
