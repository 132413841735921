/*
 * action types
 */

export const SET_COURSE = 'SET_COURSE';
export const SET_GROUP = 'SET_GROUP';
export const SET_NEW_GROUP = 'SET_NEW_GROUP';

/*
 * action creators
 */

export const setCourse = course => {
  return {
    type: SET_COURSE,
    course,
  };
};

export const setGroup = group => {
  return {
    type: SET_GROUP,
    group,
  };
};

export const setNewGroup = newGroup => {
  return {
    type: SET_NEW_GROUP,
    newGroup,
  };
};

/*
 * Reducer
 */

const initialState = {
  course: '',
  group: '',
  newGroup: '',
};

const courseAndGroupSelectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE:
      return {
        ...state,
        course: action.course,
      };
    case SET_GROUP:
      return {
        ...state,
        group: action.group,
      };
    case SET_NEW_GROUP:
      return {
        ...state,
        newGroup: action.newGroup,
      };
    default:
      return state;
  }
};

export default courseAndGroupSelectReducer;
