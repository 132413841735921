import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Button, Modal } from 'react-bootstrap';

import translations from '../../../../../translations/importUsersModal';
import GoogleClassroomSelectionModalBody from '../GoogleClassroomSelectionModalBody';
import EmailListSelectionModalBody from '../EmailListSelectionModalBody';
import CourseAndGroupSelectFormModalBody from '../CourseAndGroupSelectFormModalBody';
import GroupSelectFormModalBody from '../GroupSelectFormModalBody';
import StudentsSelectModalBody from '../StudentsSelectModalBody';
import UsersListFormModalBody from '../UsersListFormModalBody';
import SuccessMessage from '../../SuccessMessage';
import { createGroup } from '../../../GroupsDropdown/duck';
import {
  addUsersToGroupURL,
  getGoogleClassroomCoursesURL,
  getGoogleClassroomCourseStudentsURL,
} from '../../../../../utils/urls';
import constants from '../../../../../constants/importUsersModal';
import { setStep } from '../GoogleClassroomCallback/duck';
import { http } from '../../../../../services/http';
import { setError } from '../../../../ErrorNotification/duck';
import sortGroupsByCreationDate from '../../../../../utils/sortGroupsByCreationDate';
import getArrayFromList from '../../../../../utils/parseList';
import courseAndGroupSelectFormModalBodyConstants from '../../../../../constants/courseAndGroupSelectFormModalBody';

const ImportUsersModal = ({ show, onHide }) => {
  let { id } = useParams();
  const history = useHistory();
  const step = useSelector(state => state.googleClassroomReducer.step);
  const groups = useSelector(state => state.groups);
  const students = useSelector(state => state.googleClassroomReducer.students);
  const dispatch = useDispatch();

  const [googleClassroomMessage, setGoogleClassroomMessage] = useState(false);
  const [importFromEmailList, setImportFromEmailList] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const course = useSelector(state => state.courseAndGroupSelectReducer.course);
  const group = useSelector(state => state.courseAndGroupSelectReducer.group);
  const newGroup = useSelector(
    state => state.courseAndGroupSelectReducer.newGroup
  );
  const [checkedStudentIds, setCheckedStudentIds] = useState(
    students.reduce(
      (ac, cv) => ({
        ...ac,
        [cv.id]: true,
      }),
      {}
    )
  );
  const [emailList, setEmailList] = useState('');

  const handleCheckedStudentIdsChange = event => {
    const { id: uId, checked } = event.target;
    setCheckedStudentIds(prevCheckedStudentIds => ({
      ...prevCheckedStudentIds,
      [uId]: checked,
    }));
  };

  const handleEmailListChange = event => {
    const { value } = event.target;
    setEmailList(value);
  };

  const handleShowGoogleClassroomMessage = () => {
    setGoogleClassroomMessage(true);
  };

  const handleShowGroupSelection = () => {
    setImportFromEmailList(true);
    dispatch(setStep(2));
  };

  const handleAllowGoogleClassrooms = async () => {
    const cb = `${window.location.origin}${constants.googleClassroomCoursesCallbackRoute}`;
    window.location.href = getGoogleClassroomCoursesURL(cb);
  };

  const handleChooseGroupGoogle = () => {
    if (group !== courseAndGroupSelectFormModalBodyConstants.add) {
      const cb = `${window.location.origin}${constants.googleClassroomCourseStudentsCallbackRoute}/${group}`;
      window.location.href = getGoogleClassroomCourseStudentsURL(course, cb);
    } else {
      dispatch(createGroup(newGroup));
      const cb = `${window.location.origin}${constants.googleClassroomCourseStudentsCallbackRoute}/${group}`;
      window.location.href = getGoogleClassroomCourseStudentsURL(course, cb);
    }
  };

  const handleChooseGroupEmail = () => {
    if (group !== courseAndGroupSelectFormModalBodyConstants.add) {
      dispatch(setStep(3));
    } else {
      dispatch(createGroup(newGroup));
      dispatch(setStep(3));
    }
  };

  const handleImportUsers = async () => {
    try {
      let usersToImport;
      if (importFromEmailList) {
        if (newGroup) {
          id = groups.filter(gr => gr.name === newGroup)[0].id;
        } else {
          id = group;
        }
        usersToImport = getArrayFromList(emailList).map(user => {
          return {
            provider: 'email',
            id: user[0].trim(),
            alias: user[1] ? user[1].trim() : user[0].trim(),
          };
        });
      } else {
        if (id === courseAndGroupSelectFormModalBodyConstants.add) {
          id = sortGroupsByCreationDate(groups)[0].id;
        }
        const checkedUsersIds = Object.keys(checkedStudentIds).filter(
          checkedStudentId => checkedStudentIds[checkedStudentId]
        );
        usersToImport = checkedUsersIds.map(uId => {
          return {
            provider: 'google',
            id: uId,
            alias: students.find(student => student.id === uId).name,
          };
        });
      }
      setLoading(true);
      const { status } = await http.patch(addUsersToGroupURL(id), {
        users: usersToImport,
      });
      setLoading(false);
      if (status === 200) {
        dispatch(setStep(4));
      }
      if (status === 207) {
        const error = new Error();
        error.response = {
          status,
          message: importFromEmailList
            ? translations.someUsersFailedToImportFromEmailList
            : translations.someUsersFailedToImport,
        };
        error.title = translations.partialSuccess;
        onHide();
        dispatch(setError(error));
        history.push('/');
      }
    } catch (e) {
      setLoading(false);
      dispatch(setError(e));
      history.push('/');
    }
  };

  let modalHeaderText;
  let modalBody;
  let modalFooter;

  switch (step) {
    case 1:
      modalHeaderText = googleClassroomMessage
        ? translations.importFromGoogleClassroom
        : translations.importUsersFrom;
      modalBody = (
        <>
          <GoogleClassroomSelectionModalBody
            showMessage={googleClassroomMessage}
            onClick={handleShowGoogleClassroomMessage}
          />
          <EmailListSelectionModalBody
            showMessage={googleClassroomMessage}
            onClick={handleShowGroupSelection}
          />
        </>
      );
      modalFooter = (
        <>
          <Button variant="secondary" onClick={onHide}>
            {translations.cancel}
          </Button>
          <Button
            variant="primary"
            onClick={handleAllowGoogleClassrooms}
            disabled={!googleClassroomMessage}
          >
            {translations.next}
          </Button>
        </>
      );
      break;
    case 2:
      modalHeaderText = importFromEmailList
        ? translations.chooseGroup
        : translations.chooseGroups;
      modalBody = importFromEmailList ? (
        <GroupSelectFormModalBody
          extraOptions={[
            {
              id: courseAndGroupSelectFormModalBodyConstants.add,
              name: courseAndGroupSelectFormModalBodyConstants.newGroup,
            },
          ]}
          labelText={translations.importToGroup}
        />
      ) : (
        <CourseAndGroupSelectFormModalBody />
      );
      modalFooter = (
        <>
          <Button variant="secondary" onClick={onHide}>
            {translations.cancel}
          </Button>
          <Button
            variant="primary"
            onClick={
              importFromEmailList
                ? handleChooseGroupEmail
                : handleChooseGroupGoogle
            }
            disabled={(group === '' || group === 'add') && newGroup === ''}
          >
            {translations.next}
          </Button>
        </>
      );
      break;
    case 3:
      modalHeaderText = importFromEmailList
        ? translations.addUsersToImport
        : translations.selectUsersToImport;
      modalBody = importFromEmailList ? (
        <UsersListFormModalBody
          onChange={handleEmailListChange}
          usersList={emailList}
          label={translations.pasteUsers}
          placeholder={constants.emailListPlacholder}
        />
      ) : (
        <StudentsSelectModalBody
          onChange={handleCheckedStudentIdsChange}
          students={students}
          values={{ checkedStudentIds }}
        />
      );
      modalFooter = (
        <>
          <Button variant="secondary" onClick={onHide}>
            {translations.cancel}
          </Button>
          <Button
            variant="success"
            onClick={handleImportUsers}
            disabled={isLoading}
          >
            {isLoading ? translations.importing : translations.import}
          </Button>
        </>
      );
      break;
    case 4:
      modalHeaderText = translations.importCompleted;
      modalBody = (
        <SuccessMessage
          title={translations.usersImportedSuccessfully}
          message={
            importFromEmailList
              ? translations.seeProgress
              : `${translations.userCanAccess} ${translations.seeProgress}`
          }
        />
      );
      modalFooter = (
        <Button variant="success" onClick={onHide}>
          {translations.ok}
        </Button>
      );
      break;
    default:
      modalHeaderText = translations.importCompleted;
      modalBody = (
        <SuccessMessage
          title={translations.usersImportedSuccessfully}
          message={
            importFromEmailList
              ? translations.seeProgress
              : `${translations.userCanAccess} ${translations.seeProgress}`
          }
        />
      );
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton id="contained-modal-title-vcenter">
        {modalHeaderText}
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      <Modal.Footer>{modalFooter}</Modal.Footer>
    </Modal>
  );
};

export default ImportUsersModal;
