import React, { useState } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import translations from '../../translations/resetPassword';
import { http } from '../../services/http';
import validateForm from '../../utils/validateForm';
import { setPasswordURL } from '../../utils/urls';
// import BackToPage from './BackToPage';
import ResetPasswordForm from './ResetPasswordForm';
import NewPassword from './ResetPasswordForm/NewPassword';
import VerifyPassword from './ResetPasswordForm/VerifyPassword';

const ResetPassword = () => {
  const [values, setValues] = useState({
    password: '',
    verifyPassword: '',
  });
  const { password, verifyPassword } = values;
  const [errors, setErrors] = useState({
    password: '',
    verifyPassword: '',
  });
  const [showForm, setShowForm] = useState(true);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const setPassword = searchParams.has('set');
  const title = setPassword
    ? translations.setPassword
    : translations.resetPassword;

  const successMessage = setPassword
    ? translations.passwordHasBeenSet
    : translations.passwordHasBeenReset;

  const handleChange = event => {
    event.preventDefault();
    const { id, value } = event.target;
    setValues(prevValues => ({ ...prevValues, [id]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
  };

  const handleBlur = event => {
    const { id } = event.target;
    if (id === 'password') {
      const newErrors = validateForm(values);

      setErrors(prevErrors => ({
        ...prevErrors,
        password: newErrors.password,
      }));
    } else {
      const newErrors = validateForm(values);
      setErrors(prevErrors => ({
        ...prevErrors,
        verifyPassword: newErrors.verifyPassword,
      }));
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!Object.values(validateForm(values)).length) {
      try {
        await http.post(setPasswordURL(), {
          password,
          token,
        });
        setShowForm(false);
      } catch (e) {
        const { data } = e.response;
        setErrors(prevErrors => ({
          ...prevErrors,
          verifyPassword: data,
        }));
      }
    } else {
      setErrors(validateForm(values));
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="overflow-hidden border-2 my-5">
            <Card.Body className="p-0">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div className="p-5">
                    <Card.Title className="text-center">{title}</Card.Title>
                    {showForm ? (
                      <ResetPasswordForm
                        handleSubmit={handleSubmit}
                        values={values}
                      >
                        <NewPassword
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          password={password}
                          errors={errors}
                        />
                        <VerifyPassword
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          verifyPassword={verifyPassword}
                          errors={errors}
                        />
                      </ResetPasswordForm>
                    ) : (
                      <Alert variant="success" className="mt-5 mb-5">
                        {successMessage}
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
