import React from 'react';
import { Form } from 'react-bootstrap';

const VerifyPassword = ({
  handleChange,
  handleBlur,
  verifyPassword,
  errors,
}) => {
  return (
    <Form.Group controlId="verifyPassword">
      <Form.Control
        isInvalid={verifyPassword && !!errors.verifyPassword}
        type="password"
        placeholder="Verify password"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Form.Control.Feedback type="invalid" className="text-left">
        {errors.verifyPassword}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default VerifyPassword;
