import React, { useEffect } from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useCustomAccessTrees } from '../../../hooks/useCustomAccessTrees';

import './index.css';
import translations from '../../../translations/customAccessDropdown';
import { createGroup, fetchGroups } from './duck';

const CustomAccessDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let customAccessTrees = useCustomAccessTrees();

  const groups = useSelector(state => state.groups);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  const handleSelectGroup = id => {
    // find the group with the custom access name
    if (groups === undefined || groups.length === 0) {
      console.log("Groups not loaded yet");
      return;
    }

    const group = groups.find(obj => {
      return obj.name === `custom-access-${id}`
    });

    if (group) {
      history.push(`/groups/${group.id}`);
    }

    if (!group) {
      console.log('Creating group:', `custom-access-${id}`);
      dispatch(createGroup(`custom-access-${id}`));
    }
  };

  return (
    <>
      <Dropdown as={NavItem} show>
        <Dropdown.Toggle
          as={NavLink}
          className="groups-dropdown-toggle"
          data-testid="dropdown-toggle"
        >
          <FontAwesomeIcon icon={faScroll} className="mr-2" />
          <span>{translations.groups}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>

          <Dropdown.Header className="groups-dropdown-header">
            <span>{translations.myGroups}</span>
          </Dropdown.Header>
          <div className="groups-dropdown-items">
            {
              Object.keys(customAccessTrees).map((key, index) => {
                let name = '';
                const tree = customAccessTrees[key];

                if (tree.length > 0) {
                  name = tree[0].shortName || tree[0].name;
                }

                return (
                  <Dropdown.Item
                    title={name}
                    key={index}
                    eventKey={index}
                    className="groups-dropdown-item"
                    onSelect={(e) => handleSelectGroup(key)}
                  >
                    {name}
                  </Dropdown.Item>
                )
              })
            }

          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CustomAccessDropdown;
