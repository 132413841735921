import React from 'react';

const SuccessMessage = ({ title, message }) => {
  return (
    <>
      <p>{title}</p>
      <p>{message}</p>
    </>
  );
};

export default SuccessMessage;
