/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Container, Row, Button } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import translations from '../../../translations/account';
import Header from './Header';
import Section from './Section';
import InlineEmailForm from './InlineEmailForm';
import AccountButton from './AccountButton';
import CheckboxList from './CheckboxList';
import AccountCheckbox from './AccountCheckbox';
import AddEmailForm from './AddEmailForm';
import { http } from '../../../services/http';
import {
  requestVerificationEmailURL,
  googleConnectURL,
  // googleDisconnectURL,
  getCurrentUserURL,
  deleteAccountURL,
  getHostedPageURL,
  currentUserSyncPurchasesURL,
  cancelSubscriptionURL,
} from '../../../utils/urls';
import { useAlert } from '../../../utils/showAlert';
import SuccessAlert from '../../SuccessAlert';
import InfoAlert from '../../InfoAlert';
import ErrorAlert from '../../ErrorAlert';
import ConfirmationModal from '../ConfirmationModal';
import constants from '../../../constants/account';
import b64DecodeUnicode from '../../../utils/b64DecodeUnicode';
import './index.css';

const Account = ({
  minAmountToUpgrade,
  existingAmount,
  numberOfSubscribedUsers,
}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const initialIdentities =
    user && user.identities
      ? user.identities.map(identity => identity.provider)
      : [];
  const [identities, setIdentities] = useState(initialIdentities);
  const hasGoogleIdentity = identities.includes('google');
  const hasEmailIdentity = identities.includes('email');
  const emailIdentity = hasEmailIdentity
    ? user.identities.find(identity => identity.provider === 'email')
    : {};

  const email = emailIdentity.id || '';
  const yourEmail = emailIdentity.id || translations.yourEmail;
  const [usersTotal, setUsersTotal] = useState(minAmountToUpgrade);
  const [isConfirmationDisplayed, setIsConfirmationDisplayed] = useState(false);

  const isEmailVerified = !!(emailIdentity && emailIdentity.emailVerified);
  // Is upgraded by subscription, one-time or from a group
  const isUpgraded = user.upgrades && user.upgrades.length > 0;
  // Has a subscription themselves
  const isSubscribed =
    user.upgrades &&
    user.upgrades.some(
      upgrade =>
        upgrade.upgradedBy === user.id && upgrade.source === 'subscription'
    );

  const getChargebeeSubscription = () => {
    const cbSubscription = user.purchases.subscriptions.find(
      subscription => subscription.provider === 'chargebee'
    );
    return cbSubscription || {};
  };
  const chargebeeSubscription = isSubscribed ? getChargebeeSubscription() : {};

  const { pricePerUser } = constants;
  const totalPrice = (usersTotal * pricePerUser).toFixed(2);

  const history = useHistory();
  const alert = useAlert();

  let errorTitle = translations.error;
  let errorMessage = translations.errorMessageGeneral;
  let successTitle = translations.success;
  let successMessage = translations.success;

  const location = useLocation();
  // const dispatch = useDispatch();

  useEffect(() => {
    setUsersTotal(minAmountToUpgrade);
  }, [minAmountToUpgrade]);

  useEffect(() => {
    const { search } = location;
    if (search) {
      const callbackData = b64DecodeUnicode(
        new URLSearchParams(search).get('data')
      );
      const { status } = JSON.parse(callbackData);
      if (status && status >= 300) {
        switch (status) {
          case 409:
            alert.error(errorTitle, translations.googleInUse);
            break;
          case 401:
            alert.error(errorTitle, translations.googleAlreadyConnected);
            break;
          default:
            alert.error(errorTitle, errorMessage);
            break;
        }
      } else {
        alert.success(successTitle, translations.googleConnected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const { data: currentUser } = await http.get(getCurrentUserURL());
        localStorage.setItem('user', JSON.stringify(currentUser));
        history.push('/account');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    fetchCurrentUser();
  }, [history]);

  useEffect(() => {
    if (!document.getElementById('chargebee-script')) {
      const chargebeeScript = document.createElement('script');
      chargebeeScript.setAttribute(
        'src',
        'https://js.chargebee.com/v2/chargebee.js'
      );
      document.body.appendChild(chargebeeScript);
      chargebeeScript.onload = () => {
        window.Chargebee.init({
          site: 'notysing',
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = date => {
    return `${
      date.getDate() + 1 < 10 ? `0${date.getDate()}` : date.getDate()
    }.${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }.${date.getFullYear()}`;
  };

  const handleSaveEmailSuccess = () => {
    history.push('/account');
    alert.success(successTitle, translations.emailSaved);
  };

  const handleVerifyEmail = async () => {
    const { client, verifyEmailUrl } = constants;
    successTitle = translations.checkMail;
    successMessage = `${translations.checkMailMessage} ${yourEmail}.`;
    if (email) {
      try {
        await http.post(requestVerificationEmailURL(), {
          client,
          verificationLink: `${window.location.host}${verifyEmailUrl}`,
        });
        alert.success(successTitle, successMessage);
      } catch (e) {
        alert.error(errorTitle, errorMessage);
      }
    } else {
      errorMessage = translations.emptyEmail;
      alert.error(errorTitle, errorMessage);
    }
  };

  const handleResetPassword = async () => {
    history.push('/reset-password-from-account');
  };

  const handleRangeChange = event => {
    const { value } = event.target;
    setUsersTotal(value);
  };

  const handleGoogleOptionChange = async () => {
    if (hasGoogleIdentity) {
      if (identities.length > 1) {
        // successMessage = translations.googleIsDisconnected;
        // try {
        //   await http.post(googleDisconnectURL());
        //   try {
        //     const { data: updatedUser } = await http.get(getCurrentUserURL());
        //     localStorage.setItem('user', JSON.stringify(updatedUser));
        //     // eslint-disable-next-line no-empty
        //   } catch (e) { }
        //   alert.success(successTitle, successMessage);
        // } catch (e) {
        //   alert.error(errorTitle, errorMessage);
        // }
      } else {
        errorMessage = translations.unableToDisconnectGoogle;
        alert.error(errorTitle, errorMessage);
      }
    } else {
      const cb = `${window.location.origin}${constants.connectGoogleCallbackRoute}`;
      window.location.href = googleConnectURL(cb);
    }
  };

  // const showConfirmationModal = () => {
  //   setIsConfirmationDisplayed(true);
  // };

  const closeConfirmationModal = () => {
    setIsConfirmationDisplayed(false);
  };

  const handleDeleteAccount = async () => {
    closeConfirmationModal();

    try {
      await http.post(deleteAccountURL());
      localStorage.removeItem('user');
      localStorage.removeItem('courseTree');
      localStorage.removeItem('courseTreeTimestamp');
      history.push('/login');
    } catch (e) {
      alert.error(errorTitle, errorMessage);
    }
  };

  const emailVerificationRequired = async () => {
    try {
      let isEmailVerificationRequired = false;
      const { data: currentUser } = await http.get(getCurrentUserURL());
      localStorage.setItem('user', JSON.stringify(currentUser));
      const currentEmailIdentity = currentUser.identities
        ? currentUser.identities.find(identity => identity.provider === 'email')
        : {};
      if (
        !hasEmailIdentity ||
        (currentEmailIdentity && !currentEmailIdentity.emailVerified)
      ) {
        isEmailVerificationRequired = true;
      }
      return isEmailVerificationRequired;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const syncPurchases = async () => {
    try {
      const { data: updatedUser } = await http.get(
        currentUserSyncPurchasesURL()
      );
      localStorage.setItem('user', JSON.stringify(updatedUser));
      history.push('/account');
    } catch (e) {
      alert.error(errorTitle, errorMessage);
    }
  };

  const openCheckoutWindow = (type, redirectUrl, payload) => {
    const cbInstance = window.Chargebee.getInstance();
    cbInstance.openCheckout({
      hostedPage: async () => {
        const {
          data: { hosted_page: hostedPage },
        } = await http.post(getHostedPageURL(type, redirectUrl), payload);
        return hostedPage;
      },
      error: () => {
        alert.error(errorTitle, errorMessage);
      },
      success: syncPurchases,
    });
  };

  const manageSubscription = async (type, redirectUrl, payload) => {
    errorTitle = translations.actionRequired;
    errorMessage = translations.reloadAndVerify;
    const isEmailVerificationRequired = await emailVerificationRequired();
    if (isEmailVerificationRequired) {
      alert.error(errorTitle, errorMessage);
    } else {
      openCheckoutWindow(type, redirectUrl, payload);
    }
  };

  const checkoutNewSubscription = () => {
    const type = 'checkout_new_for_items';
    const redirectUrl = constants.chargeeBeeCheckoutRedirectUrl;
    // const redirectUrl = `${window.location.origin}${constants.chargeeBeeCheckoutRedirectUrl}`;
    const payload = {
      subscription_items: [
        {
          item_price_id: 'notysing_education-USD-Yearly',
          quantity: usersTotal,
        },
      ],
    };
    manageSubscription(type, redirectUrl, payload);
  };

  const checkoutExstingSubscription = () => {
    const type = 'checkout_existing_for_items';
    const redirectUrl = constants.chargeeBeeCheckoutRedirectUrl;
    // const redirectUrl = `${window.location.origin}${constants.chargeeBeeCheckoutRedirectUrl}`;
    const payload = {
      subscription: {
        id: chargebeeSubscription.providerId,
      },
      subscription_items: [
        {
          item_price_id: chargebeeSubscription.id,
          quantity: chargebeeSubscription.quantity,
        },
      ],
    };
    manageSubscription(type, redirectUrl, payload);
  };

  const cancelSubscription = async () => {
    try {
      await http.post(cancelSubscriptionURL());
      try {
        const { data: updatedUser } = await http.get(getCurrentUserURL());
        localStorage.setItem('user', JSON.stringify(updatedUser));
        history.push('/account');
        alert.success(
          successTitle,
          translations.aboutCancelledSubscription(
            formatDate(new Date(chargebeeSubscription.periodEnd))
          )
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } catch (e) {
      alert.error(errorTitle, errorMessage);
    }
  };

  return (
    <>
      <SuccessAlert />
      <InfoAlert />
      <ErrorAlert />
      <ConfirmationModal
        show={isConfirmationDisplayed}
        onCancel={closeConfirmationModal}
        onConfirm={handleDeleteAccount}
      />
      <Container fluid className="content">
        <Header name={translations.account} />
        <hr />
        {isSubscribed && !chargebeeSubscription.isCancelled && (
          <Section
            name={translations.yourSubscription}
            multiParagraphDescription={[
              translations.aboutSubscription(
                existingAmount,
                numberOfSubscribedUsers
              ),
              translations.editSubscriptionFromHostedPage,
            ]}
          >
            <Row className="pl-3 pb-3 w-100">
              <Button
                variant="primary"
                size="sm"
                className="text-uppercase mr-2"
                onClick={checkoutExstingSubscription}
              >
                {translations.manageSubscription}
              </Button>
              <Button
                variant="danger"
                size="sm"
                className="text-uppercase mr-2"
                onClick={cancelSubscription}
              >
                {translations.cancelSubscription}
              </Button>
            </Row>
          </Section>
        )}
        {isSubscribed && chargebeeSubscription.isCancelled && (
          <Section
            name={translations.yourSubscription}
            multiParagraphDescription={[
              translations.aboutCancelledSubscription(
                formatDate(new Date(chargebeeSubscription.periodEnd))
              ),
              translations.reactivateSubscriptionDescription,
            ]}
          >
            <Row className="pl-3 pb-3 w-100">
              <Button
                variant="primary"
                size="sm"
                className="text-uppercase mr-2"
                onClick={checkoutExstingSubscription}
              >
                {translations.manageSubscription}
              </Button>
            </Row>
          </Section>
        )}
        {!isSubscribed && (
          <Section
            name={
              isUpgraded ? translations.subscribe : translations.upgradeAccount
            }
            multiParagraphDescription={[
              isUpgraded
                ? translations.alreadyUpgraded
                : translations.unlockAll,
              translations.youHaveUserTotal(existingAmount, minAmountToUpgrade),
              translations.changeTheNumber,
            ]}
          >
            <div className="userSlider">
              <RangeSlider
                value={usersTotal}
                onChange={handleRangeChange}
                min={30}
                max={1000}
                step={10}
                variant="success"
                tooltip="on"
                tooltipPlacement="top"
                inputProps={{ style: { marginTop: '1em' } }}
              />
            </div>
            <p>{`${usersTotal} ${translations.users} = $${totalPrice} ${translations.perYear}`}</p>
            {!isEmailVerified && (
              <p>
                {translations.actionHelp}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={() => {
                    alert.info(
                      translations.actionRequired,
                      translations.actionHelpMessage
                    );
                  }}
                >
                  {translations.readMore}
                </a>
              </p>
            )}
            <Row className="pl-3 pb-3 w-100">
              <Button
                variant="success"
                size="sm"
                className="text-uppercase"
                disabled={!isEmailVerified}
                onClick={checkoutNewSubscription}
              >
                {translations.upgradeAccount}
              </Button>
            </Row>
          </Section>
        )}
        <hr />
        {hasEmailIdentity && (
          <Section
            name={translations.changeEmail}
            description={translations.changeEmailDescription}
          >
            <InlineEmailForm
              initialEmail={email}
              handleSaveEmailSuccess={handleSaveEmailSuccess}
            />
          </Section>
        )}
        {!hasEmailIdentity && (
          <Section
            name={translations.addEmail}
            description={translations.addEmailDescription}
          >
            <AddEmailForm
              identities={identities}
              setIdentities={setIdentities}
              handleSaveEmailSuccess={handleSaveEmailSuccess}
            />
          </Section>
        )}
        <hr />
        <Section
          name={translations.resetPassword}
          description={translations.resetPasswordDescription}
        >
          <AccountButton
            onClick={handleResetPassword}
            variant="primary"
            text={translations.resetPassword}
          />
        </Section>
        {!isEmailVerified && <hr />}
        {!isEmailVerified && (
          <Section
            name={translations.verifyEmail}
            description={`${translations.verifyEmailDescription} ${yourEmail}.`}
          >
            <AccountButton
              onClick={handleVerifyEmail}
              variant="primary"
              text={translations.verifyEmail}
            />
          </Section>
        )}
        <hr />
        <Section name={translations.connectSocialLogins}>
          <CheckboxList>
            <AccountCheckbox
              title={translations.googleLoginTitle}
              label={translations.googleLoginLabel}
              checked={hasGoogleIdentity}
              onChange={() => {
                handleGoogleOptionChange();
              }}
            />
          </CheckboxList>
        </Section>
        {/* Uncomment when functionality is ready */}
        {/* <hr />
        <Section
          name={translations.deleteAccount}
          description={translations.deleteAccountDescription}
        >
          <AccountButton
            onClick={showConfirmationModal}
            variant="danger"
            text={translations.deleteAccount}
          />
        </Section> */}
      </Container>
    </>
  );
};

export default Account;
