const isCourseTreeEmpty = courseTree =>
  !courseTree
  || typeof courseTree === 'undefined'
  || (
    Array.isArray(courseTree) &&
    courseTree.length === 1 &&
    Object.keys(courseTree[0]).length === 0 &&
    courseTree[0].constructor === Object
  );

export default isCourseTreeEmpty;
